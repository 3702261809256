import React from "react"
import BuyHomeForm from "../components/BuyHomeForm/BuyHomeForm"
import MinimalLayout from "../components/Layouts/MinimalLayout"
function BuyHomePage() {
  return (
    <MinimalLayout>
      <BuyHomeForm />
    </MinimalLayout>
  )
}

export default BuyHomePage
