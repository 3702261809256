export function ValidateEmail(value) {
  if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(value)) {
    return true
  }
  return false
}

export function BuyHomeStep1(inputs, validateStep1, setStep, step) {
  inputs.forEach((input, index) => {
    if (input.value === "") {
      validateStep1[index].innerHTML = "Required field"
      setStep(step + 0)
    } else if (!isNaN(parseInt(input.value))) {
      validateStep1[index].innerHTML = "Must be text"
      setStep(step + 0)
    } else if (input.type === "email") {
      if (!ValidateEmail(input.value)) {
        validateStep1[index].innerHTML = "Wrong email format"
        setStep(step + 0)
      }
    } else {
      validateStep1[index].innerHTML = ""
      setStep(step + 1)
    }
  })
}

export function BuyHomeStep2(inputs, validateStep2, setStep, step) {
  if (typeof document !== "undefined") {
    let errorCount = [];

    const checkboxValidationMessage = document.getElementById('checkbox_validation_message')

    inputs.forEach(input => {
      const id = input.getAttribute('id')

      if (id === 'input_43_1' || id === 'input_24_1') {
        if (!input.checked && input.getAttribute('required') === 'required') {
          if (checkboxValidationMessage) {
            checkboxValidationMessage.innerHTML = "required field"
            errorCount.push(input)
          }
        } else {
          if (checkboxValidationMessage) {
            const index = errorCount.indexOf(input);

            if (index !== -1) {
              errorCount.splice(index, 1);
            }

            checkboxValidationMessage.innerHTML = ""
          }
        }
      } else {
        if (input.value === "") {
          validateStep2.innerHTML = "required field"
          errorCount.push(input)
        } else if (isNaN(parseInt(input.value))) {
          validateStep2.innerHTML = "Must be a valid number"
          errorCount.push(input)
        } else if (input.value.length !== 10) {
          validateStep2.innerHTML = "Must be 10 digits long"
          errorCount.push(input)
        } else {
          const index = errorCount.indexOf(input);

          if (index !== -1) {
            errorCount.splice(index, 1);
          }

          validateStep2.innerHTML = ""
        }
      }
    })

    let uniqueItems = [...new Set(errorCount)]

    if (!uniqueItems.length) {
      setStep(step + 1)
    }
  }
}

export function BuyHomeStep3(inputs, validateStep3, setStep, step) {
  inputs.forEach((input, index) => {
    if (input.value === "") {
      validateStep3[index].innerHTML = "Required field"
    } else if (isNaN(parseInt(input.value))) {
      validateStep3[index].innerHTML = "Must be a valid number"
    } else if (input.value.length < 5) {
      validateStep3[index].innerHTML = "At least 5"
    } else {
      setStep(step + 1)
      validateStep3[index].innerHTML = ""
    }
  })
}

export function BuyHomeStep4(inputs, validateStep4, setStep, step) {
  inputs.forEach((input, index) => {
    console.log(input)
    // if (input.value === "") {
    //   validateStep4[index].innerHTML = "Required field"
    // } else {
      setStep(step + 1)
      // validateStep4[index].innerHTML = ""
    // }
  })
}

export function RefinancingHomeStep3(inputs, validateStep3, setStep, step) {
  inputs.forEach((input, index) => {
    if (input.value === "") {
      validateStep3[index].innerHTML = "Required field"
    } else {
      setStep(step + 1)
      validateStep3[index].innerHTML = ""
    }
  })
}

